.main {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 2rem;
  padding-bottom: 1rem;
  padding: 1rem;
}

.dark {
  width: 100%;
  max-width: 32rem;
  padding: 1rem;
	margin-top: 2rem;
}

.description {
  opacity: 0.64;
  grid-area: description;
}

.item {
  display: grid;
  grid-template-areas: "title price" "description price";
  justify-content: space-between;
  text-align: left;
  border-radius: 1rem;
  column-gap: 0.5rem;
  width: 100%;
  max-width: 48rem;
}

.item span {
  opacity: 0.64;
}

.list {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  padding: 1rem;
  max-width: 32rem;
}

.price {
  grid-area: price;
}

.title {
  grid-area: title;
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
  max-width: 48rem;
  border-top: 0.15rem solid rgba(255, 255, 255, 0.32);
  padding-top: 1rem;
}
