input[type="radio"] {
  display: none;
}

.box {
  height: 2.25rem;
  width: 2.25rem;
  background: rgba(255, 255, 255, 0.32);
}

.check {
  height: 1rem;
  width: 1rem;
}

.checked {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  position: absolute;
  right: 0.64rem;
  opacity: 0.64;
}

.label:hover {
  cursor: pointer;
}

.legendBlank {
  opacity: 1;
}

.legendError {
  color: #ffb8b8;
}

.main {
  display: flex;
  flex-flow: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  border-radius: 1rem;
  border: none;
  width: 100%;
  background: rgba(255, 255, 255, 0.16);
  padding: 1rem;
}

.option {
  background: none;
  border: none;
  border-radius: 0;
  position: relative;
}

.option {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 0;
  height: 2.25rem;
  padding-right: 1rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.option:hover {
  border: none;
  background: rgba(255, 255, 255, 0.16);
}

.option:hover .box {
  background: none;
}

.selected {
  /* background: rgba(255, 255, 255, 0.32); */
}
