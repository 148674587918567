/* TODO: UPDATE THESE COLORS */

.error {
  background: #bc0000;
}

.success {
  background: #247e0e;
}

.notification {
  border: 0.2rem solid white;
  color: white;
  padding: 1rem;
  width: 100%;
  max-width: 32rem;
  border-radius: 1rem;
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  align-items: center;
  z-index: 3;
}

.notification:hover {
  cursor: pointer;
}

.notifications {
  width: 100%;
  position: fixed;
  top: 1rem;
  left: 0;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  align-items: center;
  padding: 0 1rem;
  z-index: 2;
}
