.img {
  width: 100%;
  max-width: 64rem;
  opacity: 0.64;
}

.check {
  height: 1rem;
  width: 1rem;
}

.copper * {
  color: #ee892b;
}

.gold * {
  color: #e1c242;
}

.rosegold * {
  color: #de93cf;
}

.silver * {
  color: #c0c0c0;
}

.chosen {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 1rem;
}

.current {
  font-size: 4rem;
  width: fit-content;
}

.customization {
  grid-area: customization;
}

.delete {
  background: rgba(255, 255, 255, 0.32);
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  border: none;
  padding: 0;
  color: white;
  grid-area: button;
}

.description {
  text-align: left;
}

.email {
  grid-area: email;
  color: white;
}

.entry {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-areas: "button top" "button bottom";
  grid-template-columns: 2rem auto;
  column-gap: 1rem;
  align-items: center;
}

.entry:hover {
  cursor: pointer;
}

.indicator {
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
}

.disconnected {
  background: #ffb8b8;
}

.connected {
  background: #dbffd2;
}

.list {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 1rem;
  max-height: calc(100vh - 36vh);
  background: rgba(255, 255, 255, 0.16);
  overflow-y: scroll;
}

.main {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  border-radius: 2rem;
  position: relative;
}

.name {
  font-size: 1.5rem;
  text-align: left;
  display: flex;
  gap: 1rem;
}

.name:hover {
  cursor: pointer;
}

.number {
  font-size: 4rem;
  opacity: 0.64;
}

.ordered {
  opacity: 0.64;
}

.o64 {
  opacity: 0.64;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.specifics {
  grid-area: bottom;
  text-align: left;
}

.sponsor {
  max-width: 84%;
  border-radius: 1rem;
}

.stat {
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 15rem;
}

.stats {
  display: flex;
  padding: 1rem;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 1rem;
}

.text {
  font-size: 2rem;
  opacity: 0.64;
}

.winner {
  width: 100%;
  padding: 1rem;
  padding-top: 0;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 1rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
