.container {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  padding: 0 1rem;
  width: 100%;
  max-width: 32rem;
}

.main {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
}

.strong {
  width: 100%;
  max-width: 48rem;
  text-align: left;
}

.ul,
.ol {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  align-items: baseline;
  width: 100%;
  max-width: 48rem;
}
