.main {
  width: 100%;
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-template-areas: "checkbox primary";
  gap: 1rem;
  padding-right: 1rem;
  align-items: center;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.main:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.16);
}

.main:hover .checkbox {
  background: none;
}

.main2 {
  grid-template-columns: 2rem 1fr 2rem;
  grid-template-areas: "checkbox primary secondary";
  padding-right: 0.5rem;
}

.check {
  width: 1.4rem;
}

.checkbox {
  height: 2rem;
  width: 2rem;
  border-radius: 0;
  grid-area: checkbox;
  border: none;
  background: rgba(255, 255, 255, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: checkbox;
}

.checked {
  background: none;
}

.primary {
  grid-area: primary;
}

.secondary {
  grid-area: secondary;
  /* width: 100%;
  height: 100%;
  background: green; */
}
