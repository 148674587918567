.button {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
  border: none;
  position: relative;
  background: none;
}

.button:hover {
  cursor: pointer;
  border: none;
}

.caret {
  position: absolute;
  right: 1rem;
  height: 1rem;
  width: 1rem;
  background: url("./caret.png");
  background-size: cover;
  transform: rotate(270deg);
  transition: all 0.2s ease;
}

.caret_open {
  transform: rotate(180deg);
}

.disabled {
  opacity: 0.48;
}

.main {
  width: 100%;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 1.625rem;
}

.more {
  width: 100%;
  display: flex;
  flex-flow: column;
  padding: 1rem;
  padding-top: 0;
  gap: 1rem;
}
