.main {
  width: 100%;
  height: 3rem;
  display: grid;
  grid-template-columns: 1fr 3rem 2.5rem;
  align-items: center;
}

.delete {
  height: 2rem;
  width: 2rem;
  background-image: url("https://storage.googleapis.com/squidslippers-media/icons/x.png");
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.64;
}

.delete:hover {
  background-color: rgba(255, 255, 255, 0.16);
  cursor: pointer;
}

.diglett {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 2rem 1fr;
  align-items: center;
  gap: 0.5rem;
  display: grid;
  background: linear-gradient(to right, #161616, #16161600),
    linear-gradient(to top, #7d2a00, #cd5e27);
}

.price {
  text-align: right;
}

.head {
  background: url("./diglett.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
}
