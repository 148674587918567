.ball {
  height: 1rem;
  width: 1rem;
  border-radius: 1rem;
  position: absolute;
  right: 0;
  background: white;
  box-shadow: 0 0 0 0.1rem white, 0 0 0.5rem 0.2rem #282828;
}

.graph {
  width: 100%;
  height: 1rem;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 1rem;
  display: flex;
}

.horizontal {
  position: relative;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  border-radius: 1rem;
}

.milestone {
  background: white;
  height: 1rem;
  width: 100%;
}

.milestones {
  width: 100%;
  gap: 0.2rem;
  display: grid;
  grid-template-columns: repeat(13, 1fr);
}

.vertical {
  height: 2rem;
  margin: -0.5rem 0;
  width: 0.2rem;
  border-radius: 0.2rem;
  background: rgba(255, 255, 255, 0.32);
  margin-left: 0.4rem;
}
