.list {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0;
  width: 100%;
  padding: 0;
}

.main {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding-bottom: 1rem;
}

.remove {
  background: none;
  border: none;
  left: 0;
  width: 100%;
  color: #ffb8b8;
  background: rgba(255, 255, 255, 0.08);
}

.remove:hover {
  border: none;
}

.total {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  max-width: 32rem;
}
