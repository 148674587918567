@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

* {
  color: white;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

fieldset {
  border: none;
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 1rem;
}

.button {
  padding: 1rem;
  border-radius: 2rem;
  border: 0.125rem solid white;
  background: linear-gradient(to top, #161616, #16161600),
    linear-gradient(to right, #1f4ceb, #a413b1);
  color: white;
  text-align: center;
  width: 100%;
}

button:hover {
  cursor: pointer;
}

form {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

h1,
h2 {
  width: 100%;
  text-align: center;
  font-size: 2rem;
}

h1 {
  font-weight: 700;
}

h2 {
  font-weight: 400;
}

h3 {
  width: 100%;
  text-align: left;
  font-weight: 700;
}

h4 {
  width: 100%;
  text-align: left;
  /* opacity: 0.64; */
}

a {
  text-decoration: none;
}

p,
code {
  text-align: left;
  width: 100%;
}

strong {
  font-weight: 700;
}

ul {
  list-style: none;
  width: 100%;
  display: flex;
  flex-flow: column;
  /* align-items: baseline; */
  gap: 0.5rem;
}

li {
  text-align: left;
}

.App {
  text-align: center;
  color: #161616;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #161616;
}

.background {
  background: rgba(255, 255, 255, 0.16);
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  width: 100%;
}

.card {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  /* gap: 0.5rem; */
}

.column {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  max-width: 64rem;
}

.columns {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(auto, 30.5rem));
  align-items: start;
  gap: 1rem;
  justify-content: center;
}

.columns3 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.ctext {
  text-align: center;
}

.disabled {
  opacity: 0.48;
  cursor: default;
}

.error {
  color: #f2d2bd;
}

.between {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.flex {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.wrap {
  flex-wrap: wrap;
  justify-content: center;
}

.gradient {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to top, #161616, #16161600),
    linear-gradient(to right, #1f4ceb, #a413b1);
}

.inlink {
  color: #bbcaff;
  text-decoration: underline;
}

.link {
  color: white;
  padding: 1rem;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.16);
  border: 0.125rem solid rgba(255, 255, 255, 0);

  text-align: center;
  width: 100%;
}

.link:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.24);
  border: 0.125rem solid rgba(255, 255, 255, 0);
}

.flink {
  padding: 1rem;
  text-align: center;
  width: 100%;
  opacity: 0.64;
}

.linkout {
  color: #8fc7ff;
}

.main {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-bottom: 2rem;
}

.o16 {
  opacity: 0.16;
}

.o4 {
  opacity: 0.4;
}

.o64 {
  opacity: 0.64;
}

.p01 {
  padding: 0 1rem;
}

.pokebubble {
  background: rgba(255, 255, 255, 0.16);
  padding: 0.5rem;
  width: fit-content;
}
