.background {
  background: linear-gradient(to top, #161616, #00000000),
    linear-gradient(to right, #1f4ceb, #a413b1);
  max-width: 100vw;
  border-radius: 0.5rem;
  padding-top: 2rem;
}

.button {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  padding: 0;
  border: none;
  background: none;
}

.button:hover {
  border: none;
}

.ignored {
  opacity: 0.32;
}

.color {
  height: 3rem;
  width: 3rem;
  background: rgba(255, 255, 255, 0.08);
}

.pink {
  background: url("https://storage.googleapis.com/squidslippers-media/colored_paincils/pink.png");
  background-size: contain;
}
.red {
  background: url("https://storage.googleapis.com/squidslippers-media/colored_paincils/red.png");
  background-size: contain;
}
.green {
  background: url("https://storage.googleapis.com/squidslippers-media/colored_paincils/green.png");
  background-size: contain;
}
.blue {
  background: url("https://storage.googleapis.com/squidslippers-media/colored_paincils/blue.png");
  background-size: contain;
}
.purple {
  background: url("https://storage.googleapis.com/squidslippers-media/colored_paincils/purple.png");
  background-size: contain;
}
.black {
  background: url("https://storage.googleapis.com/squidslippers-media/colored_paincils/black.png");
  background-size: contain;
}
.orange {
  background: url("https://storage.googleapis.com/squidslippers-media/colored_paincils/orange.png");
  background-size: contain;
}
.yellow {
  background: url("https://storage.googleapis.com/squidslippers-media/colored_paincils/yellow.png");
  background-size: contain;
}
.white {
  background: url("https://storage.googleapis.com/squidslippers-media/colored_paincils/white.png");
  background-size: contain;
}

.form {
  background: #161616;
  border: 0.125rem solid white;
  padding: 2rem;
  max-width: 24rem;
  border-radius: 2rem;
}

.img {
  width: 100%;
  max-width: 64rem;
  margin-bottom: -2rem;
  padding: 0 1rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(22, 22, 22, 0.8);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paincils {
  gap: 0;
}
