.main {
  width: 100%;
  height: 3rem;
  display: grid;
  grid-template-columns: 1fr 3rem 2.5rem;
  align-items: center;
}

.delete {
  height: 2rem;
  width: 2rem;
  background-image: url("https://storage.googleapis.com/squidslippers-media/icons/x.png");
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.64;
}

.delete:hover {
  background-color: rgba(255, 255, 255, 0.16);
  cursor: pointer;
}

.paincil {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 2rem 1fr;
  align-items: center;
  gap: 0.5rem;
  display: grid;
}

.paincil_black {
  background: linear-gradient(to right, #161616, #16161600),
    linear-gradient(#636363, #161616);
}

.paincil_blue {
  background: linear-gradient(to right, #161616, #16161600),
    linear-gradient(#007ec2, #004469);
}

.paincil_green {
  background: linear-gradient(to right, #161616, #16161600),
    linear-gradient(#00a500, #005b00);
}

.paincil_pink {
  background: linear-gradient(to right, #161616, #16161600),
    linear-gradient(#f60084, #8f004c);
}

.paincil_orange {
  background: linear-gradient(to right, #161616, #16161600),
    linear-gradient(#e94b00, #973000);
}

.paincil_purple {
  background: linear-gradient(to right, #161616, #16161600),
    linear-gradient(#9c00c7, #5a0074);
}

.paincil_red {
  background: linear-gradient(to right, #161616, #16161600),
    linear-gradient(#e40012, #90000c);
}

.paincil_yellow {
  background: linear-gradient(to right, #161616, #16161600),
    linear-gradient(#f2b100, #836000);
}

.paincil_white {
  background: linear-gradient(to right, #161616, #16161600),
    linear-gradient(#ffffff, #717171);
}

.price {
  text-align: right;
}

.tip {
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  /* opacity: 0.72; */
}

.tip_blue {
  background-image: url("./blue.png");
}

.tip_black {
  background-image: url("./black.png");
}

.tip_green {
  background-image: url("./green.png");
}

.tip_pink {
  background-image: url("./pink.png");
}

.tip_purple {
  background-image: url("./purple.png");
}

.tip_red {
  background-image: url("./red.png");
}

.tip_orange {
  background-image: url("./orange.png");
}

.tip_yellow {
  background-image: url("./yellow.png");
}

.tip_white {
  background-image: url("./white.png");
}
