.main {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.bar {
  margin-top: 3rem;
	margin-bottom: 1.5rem;
  width: 100%;
  height: 0.5rem;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 0.5rem;
  position: relative;
}

.checkpoint {
  width: 0.2rem;
  height: 1rem;
  background: white;
}

.checkpoint_container {
  width: 0.5rem;
  height: 4rem;
  margin-bottom: -2.25rem;
  position: absolute;
  bottom: 0.5rem;
  display: flex;
  gap: 0.32rem;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.labels {
  width: 100%;
  margin-bottom: -1rem;
  display: flex;
  justify-content: space-between;
}
