.check {
  height: 1.2rem;
  width: 1.2rem;
}

.checkbox {
  height: 2rem;
  width: 2rem;
  background: rgba(255, 255, 255, 0.32);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border: none;
}

.checkbox:hover {
  cursor: pointer;
  border: none;
}

.checked {
  background: none;
}

.header {
  position: sticky;
  top: 0;
  background: rgba(22, 22, 22, 0.64);
  z-index: 2;
  padding: 1rem;
  width: 100%;
}

.instruction {
  background: linear-gradient(
    to right,
    rgba(31, 76, 235, 0.48),
    rgba(164, 19, 177, 0.48)
  );
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  width: 100%;
}

.right {
  position: absolute;
  right: 1rem;
  text-align: right;
}

.step {
  display: grid;
  grid-template-columns: 2rem auto;
  align-items: center;
  gap: 1rem;
  position: relative;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  width: 100%;
}

.step:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.16);
}

.step:hover .checkbox {
  background: none;
}

.summary {
  background: rgba(255, 255, 255, 0.32);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  gap: 1rem;
}

.time {
  font-size: 3rem;
}

.time_idle {
  opacity: 0.32;
}

.timer {
  width: 100%;
  display: flex;
  flex-flow: column;
  background: rgba(255, 255, 255, 0.08);
  padding: 1rem;
  border-radius: 0.5rem;
  gap: 0.5rem;
}
