.children {
  position: absolute;
  right: 0.64rem;
  bottom: 0.5rem;
  opacity: 0.64;
}

.input {
  border: none;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  transition: all 0.2s;
  padding: 0.5rem 0.8rem;
  background: rgba(255, 255, 255, 0.16);
  caret-color: #161616;
}

.inputBlank {
  background: white;
}

.inputError {
  color: #bc0000;
  background: #ffb8b8;
}

.label {
  font-size: 1rem;
  opacity: 0.64;
}

.labelBlank {
  opacity: 1;
}

.labelError {
  opacity: 1;
  color: #ffb8b8;
}

.main {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 0.2rem;
  border: none;
  text-align: left;
  position: relative;
  width: 100%;
}
