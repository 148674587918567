.main {
  position: fixed;
  z-index: 3;
  top: 1rem;
  right: 1rem;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to top, #161616, #00000000),
    linear-gradient(to right, #1f4ceb, #a413b1);
  border: 0.15rem solid white;
}

.main:hover {
  cursor: pointer;
}

.remove {
  background: none;
  padding: 0.5rem;
  border: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #ffb8b8;
}

.remove:hover {
  border: none;
  background: rgba(255, 255, 255, 0.16);
}