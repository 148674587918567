.container {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  max-width: 32rem;
}

.icon {
  width: 2rem;
  height: 2rem;
  background-size: cover;
}

.info {
  padding: 0.5rem 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  border-radius: 1rem;
  width: 100%;
}

.invisilink {
  opacity: 0;
  transition: all 0.2s;
}

.invisilink:hover {
  opacity: 0.64;
}

.link {
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  background: none;
  border: 0;
}

.info:hover,
.link:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.16);
  border: none;
}

.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.logo {
  width: calc(100% - 4rem);
  aspect-ratio: 1/1;
  max-height: 16rem;
  max-width: 16rem;
  background-size: contain;
  margin-left: 1rem;
}

.main {
  width: 100%;
  margin: 0 1rem;
  padding: 2rem 0;
  background: linear-gradient(to top, #161616, #00000000),
    linear-gradient(to right, #1f4ceb, #a413b1);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
}

.row {
  display: flex;
  flex-flow: column;
  align-items: baseline;
}

.title {
  padding-left: 1rem;
}

@media (max-width: 50rem) {
  .links {
    justify-content: center;
  }

  .logo {
    margin: 0;
  }

  .row {
    align-items: center;
  }

  .title {
    padding: 0;
  }
}
