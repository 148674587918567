.main {
  background: linear-gradient(to top, #161616, #00000000),
    linear-gradient(to right, #1f4ceb, #a413b1);
  width: 100%;
  padding: 4rem 1rem;
  padding-bottom: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(auto, 30.5rem));
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.carousel {
  background: #161616;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
  position: relative;
  aspect-ratio: 4/3;
  overflow: hidden;
  width: 100%;
}

.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  max-width: 64rem;
}

.left {
  text-align: left;
  display: flex;
  flex-flow: column;
  gap: 1rem;
}

.primary {
  text-align: left;
  padding: 0;
}

.me_visible {
  width: 90%;
  /* aspect-ratio: 4/3; */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: slide-in 1s ease;
  pointer-events: none;
}

@keyframes slide-in {
  0% {
    opacity: 0;
    right: -200%;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

.me_invisible {
  width: 90%;
  aspect-ratio: 4/3;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  animation: slide-out 1s ease;
  opacity: 0;
  pointer-events: none;
}

@keyframes slide-out {
  0% {
    left: 0;
    opacity: 1;
  }
  100% {
    left: -200%;
    opacity: 0;
  }
}

.small {
  background: linear-gradient(to top, #161616, #00000000),
    linear-gradient(to right, #1f4ceb, #a413b1);
  width: 100%;
  padding: 4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
