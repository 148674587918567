.color {
  width: 100%;
  aspect-ratio: 2/1;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  padding: 0 0.5rem;
  font-weight: bold;
  border: none;
}

.color:nth-of-type(1) {
  background: linear-gradient(#ffc354, #7f5529);
}

.color:nth-of-type(2) {
  background: linear-gradient(#f5ae8a, #7a4a40);
}

.color:nth-of-type(3) {
  background: linear-gradient(#e8e8e8, #6b6b6b);
}

.color:hover {
  cursor: pointer;
}

.check {
  height: 2rem;
  width: 2rem;
  background: white;
}

.img {
  width: 8rem;
  height: initial;
  object-fit: contain;
}

.input {
  font-size: 2rem;
  background: none;
  text-align: center;
  padding: 0.5rem;
  width: 100%;
  border: none;
  border-bottom: 0.2rem solid rgba(255, 255, 255, 0.32);
}

.names {
  display: flex;
  flex-flow: column;
  gap: 0;
}
