.header {
  width: 100%;
  background: linear-gradient(to right, #1f4ceb, #a413b1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image {
  height: 2rem;
  width: 2rem;
  margin-right: 0.75rem;
}

.logo {
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 0 0.75rem;
  z-index: 3;
}

.logo:hover {
  cursor: pointer;
}

.home {
  padding: 1rem;
  background: none;
  width: fit-content;
  border: none;
}

.home:hover {
  border: none;
}

.hamburger {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: column;
  padding: 0 0.75rem;
}

.hamburger:hover {
  cursor: pointer;
}

.line {
  width: 1.75rem;
  height: 0.15rem;
  border-radius: 0.15rem;
  background: white;
}
