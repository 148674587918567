.buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 20rem;
  position: absolute;
  bottom: 4rem;
}

.button {
  height: 6rem;
  width: 6rem;
  background: rgba(255, 255, 255, 0.32);
  border-radius: 40rem;
	border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.container {
  width: 100%;
  max-width: 15rem;
  aspect-ratio: 1/1;
  background: #444;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.icon {
  width: 1.7rem;
}

.image {
  width: 100%;
  max-width: 64rem;
  max-height: calc(100vh - 14rem);
  aspect-ratio: initial;
  object-fit: contain;
  margin-bottom: 1rem;
}

.loading {
  height: 8rem;
  width: 8rem;
  background: rgba(255, 255, 255, 0.16);
  animation: loading 2s linear infinite;
  position: absolute;
  z-index: 3;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(90deg);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #000000d9;
  z-index: 2;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
}

.pictures {
  width: 100%;
  max-width: 67rem;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(4, auto);
}

.picture {
  width: 100%;
  aspect-ratio: 1/1;
  background: #444;
  border-radius: 0.5rem;
  object-fit: cover;
}

.picture:hover {
  cursor: pointer;
}

.unloaded {
  opacity: 0;
}
