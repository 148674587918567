.main {
  width: 100%;
  height: 3rem;
  display: grid;
  grid-template-columns: 1fr 2rem;
  gap: 0.5rem;
  align-items: center;
}

.delete {
  height: 2rem;
  width: 2rem;

  background-image: url("https://storage.googleapis.com/squidslippers-media/icons/x.png");
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.64;
}

.delete:hover {
  background-color: rgba(255, 255, 255, 0.16);
  cursor: pointer;
}

.wire_gold {
  background: linear-gradient(to right, #161616, #16161600),
    linear-gradient(#ffc354, #7f5529);
}

.wire_rose_gold {
  background: linear-gradient(to right, #161616, #16161600),
    linear-gradient(#f5ae8a, #7a4a40);
}

.wire_silver {
  background: linear-gradient(to right, #161616, #16161600),
    linear-gradient(#e8e8e8, #6b6b6b);
}

.price {
  text-align: right;
}

.wire {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-template-areas: delete;
  padding: 0.5rem 0;
  align-items: center;
  gap: 0.5rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
