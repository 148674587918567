.main {
  width: 100%;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.08)
  );
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 1rem;
  border-top-right-radius: 1.625rem;
  border-top-left-radius: 1.625rem;
  max-width: 20rem;
  gap: 1rem;
}

.container {
  width: 100%;
  aspect-ratio: 2/1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #161616;
  border-radius: 1rem;
}

.img {
  width: 100%;
}

.list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder {
  opacity: 0.64;
}

.primary {
  text-align: center;
}

@media (max-width: 43rem) {
  .main {
    max-width: 30rem;
  }
}
