.li {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-align: left;
  opacity: 0.64;
}

.container {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  height: 1.2rem;
  width: 1.2rem;
  opacity: 0.32;
}

.unchecked {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.16);
}
